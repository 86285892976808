<template>
  <v-container style="margin-left: 60px;">
    <v-row class="flex-row align-self-stretch">
      <v-col cols="2">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MixinPatients from '@/mixins/patients';
import MixinInterviews from '@/mixins/interviews';

export default {
  name: 'InterviewsComparison',
  mixins: [MixinInterviews, MixinPatients],
};
</script>
